<template>
    <div class="min-h-full relative max-h-full flex flex-col text-base-content">
        <sub-header :backButton="true" class="mb-4" backRedirectionUrl="/credit-packs?tab=creditSource" :customCrumbLists="customBreadCrumbs" />
        <div class="justify-between flex-grow max-h-full">
            <div class="flex h-full gap-4 flex-1">
                <div class="w-2/12 rounded-lg self-start bg-white shadow flex flex-col gap-x-4 overflow-y-auto">
                    <div @click="selectedTab(nav)"
                        v-for="(nav, idx) in navigations"
                        :key="idx"
                        class="px-4 py-3 transition-all relative duration-200 items-center text-sm font-medium flex gap-3  cursor-pointer"
                        :class="{
                            ' bg-primary-300 hover:bg-primary-200 text-base-content-800': currentTab === nav.nav,
                            'hover:bg-gray-100   hover:text-base-content-600': currentTab !== nav.nav,
                            'border-t border-gray-400 border-0' : idx != 0
                        }"
                    >
                        <font-awesome-icon :icon="[nav.iconPrefix || 'fa', nav.icon]" />
                        {{ nav.title }}
                    </div>
                </div>
                <div v-if="currentTab" class="flex-1 relative g">
                    <div class="p-1">
                        <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col" v-show="currentTab === 'Source Details'" >
                            <div class="p-3 panel-head bg-primary-300 font-semibold text-base-content flex gap-3 justify-between items-center rounded-t-2xl">
                                Feature Details
                            </div>
                            <div class="py-24 flex items-center justify-center" v-if="isLoading">
                                <Loader />
                            </div>
                            <div class="py-3 px-6" v-else>
                                <FormulateForm>
                                    <div class="grid grid-cols-2 gap-x-6">
                                        <FormulateInput 
                                            disabled 
                                            class="w-full disableInput" 
                                            label="Name" 
                                            placeholder="Comapany Domain" 
                                            type="text"
                                            :element-class=" (context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                            v-model="sourceDetails.credit_source_name"
                                        />
                                    
                                        <FormulateInput 
                                            disabled 
                                            class="w-full disableInput" 
                                            label="Credit/Hits" 
                                            placeholder="Credit/Hits" 
                                            type="text"
                                            :element-class=" (context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                            v-model="sourceDetails.credit_per_hit" 
                                        />
                                    </div>
                                    <div class="grid grid-cols-2 gap-x-6">
                                        <FormulateInput 
                                            disabled 
                                            class="w-full disableInput" 
                                            label="Input" 
                                            placeholder="Input" 
                                            type="text"
                                            :element-class=" (context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                            v-model="sourceDetails.input"
                                        />
                                    
                                        <FormulateInput 
                                            disabled 
                                            class="w-full disableInput" 
                                            label="Output" 
                                            placeholder="Output" 
                                            type="text"
                                            :element-class=" (context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                            v-model="sourceDetails.output" 
                                        />
                                    </div>
                                    
                                </FormulateForm>
                                </div>
                            </div>
                            
                            <div class="shadow-md relative rounded-2xl bg-white flex gap-6 flex-col pb-4" v-show="currentTab === 'Custom Client'" >
                                <div class="p-5 panel-head bg-primary-300 font-semibold text-base-content flex gap-3 justify-between items-center rounded-t-2xl">
                                    Custom Client
                                </div>
                                <div class="py-24 flex items-center justify-center" v-if="isLoading">
                                    <Loader />
                                </div>
                                <div class="px-5" v-else>
                                    <div>
                                        <input
                                            type="text" 
                                            placeholder="Search"
                                            class="input input-bordered input-sm w-full"
                                            v-model="searchedValue"
                                            @input="handleSearch(searchedValue, 'client')"
                                        />
                                        <div class="w-full overflow-hidden rounded-lg bg-white border-2 mt-5">
                                            <div class="overflow-auto max-h-96">
                                                <table class="min-w-full bg-white" id="clientDataTable">
                                                <thead>
                                                    <tr class="border-b">
                                                        <th
                                                            v-for="col in client_cols"
                                                            :key="col.name"
                                                            :class="{'text-left pl-3' : col.label == 'client_title' || col.label == 'tenant_title'}"
                                                        >
                                                            <span>{{ col.name }}
                                                                <svg @click="searchTenant()" class="ml-3" role="button" v-if="col.label == 'tenant_title'" xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                                                                    <path d="M11.25 0.333374V1.50004H10.6667L7.75 5.87504V10.8334H4.25V5.87504L1.33333 1.50004H0.75V0.333374H11.25ZM2.73549 1.50004L5.41667 5.52177V9.66671H6.58333V5.52177L9.26451 1.50004H2.73549Z" fill="#0D69D5"/>
                                                                </svg>
                                                            </span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="filterTenant">
                                                        <input
                                                        type="text" 
                                                        placeholder="Search Tenant"
                                                        class="input input-bordered input-sm w-full"
                                                        v-model="searchedTenantValue"
                                                        @input="handleSearchTenant(searchedTenantValue)"
                                                        />
                                                    </tr>
                                                    <tr v-for="(rowData, clntInx) in client_row" :key="clntInx" class="border-b clientTableRows">
                                                        <td class="pl-5">
                                                            {{ rowData.client_title }}
                                                        </td>
                                                        <td class="text-center border-b">
                                                            <input 
                                                                :disabled= true 
                                                                type="text" 
                                                                v-model="rowData.credit_per_hit" 
                                                                class="input input-bordered input-xs w-16 text-center" 
                                                            /> 
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div v-else class="flex flex-1 bg-white p-3 h-full overflow-y-scroll scroll-bar justify-center items-center">No Options Selected</div>
            </div>
        </div>
    </div>
</template>
<script>
import {
  getSourceList,
  updateSourceDetails,
  getTenantDetails,
  // getAssignTenanList,
  assignTenant,
  // assignClient,
} from "./services";
import SubHeader from "@/components/SubHeader";
// import Button from "@/components/button";
import Loader from "@shared/loader";
import { isNumber } from "@/plugins/functions";
import { creditAndSourceList} from "../services"
// import customTable from "./source-custom-table.vue";
// End
export default {
  name: "Sources",
  components: {
    SubHeader,
    // Button,
    Loader,
    // customTable,
  },
  data: () => ({
    changeMade: false,
    customTenantChaned: false,
    customClientChaned: false,
    searchedValue: null,
    searchedTenantValue: null,
    filterTenant: false,
    navigations: [
      {
        title: "Feature Details",
        nav: "Source Details",
        icon: "circle-check",
        subnav: false,
        iconPrefix: "far",
      },
      {
        title: "Custom Client",
        nav: "Custom Client",
        icon: "file-lines",
        subnav: false,
        iconPrefix: "far",
      },
    ],
    currentTab: "Source Details",
    tenant_cols: [{
                    name: "Tenant Name",
                    label: "title",
                    type: "custom"
                },{
                    name: "Credits/Hits",
                    label: "credit_per_hit",
                    type: "custom"
                },
                // {
                //     name: "Service Charge/Hits",
                //     label: "service_charge_per_hit",
                //     type: "custom"
                // },
                {
                    name: "Use Default",
                    label: "default",
                    type: "custom"
                },
            ],
    client_cols: [
      {
        name: "Client Name",
        label: "client_title",
        type: "custom",
      },
      {
        name: "Credits/Hits",
        label: "credit_per_hit",
        type: "custom",
      },
    ],
    caseEventDetails: {},
    tenant_row: [],
    client_row: [],
    isLoading: false,
    sourceDetails: {
      credit_source_name: "",
      nrn_name: "",
      credit_per_hit: "",
      service_charge_per_hit: 0,
      input: "",
      output: ""
    },
    service_charge_per_hit: 0,
    customBreadCrumbs: [],
    // savingAssignPacks: false,
    params: {
      page: 1,
      limit: 50,
    },
  }),
  watch: {
    sourceDetails: {
      deep: true,
      handler() {
        if (!this.isLoading) {
          this.changeMade = true;
        }
      },
    },
    tenant_row: {
      deep: true,
      handler() {
        if (!this.isLoading) {
          this.customTenantChaned = true;
        }
      },
    },
    client_row: {
      deep: true,
      handler() {
        if (!this.isLoading) {
          this.customClientChaned = true;
        }
      },
    },
    combinedData: {
      deep: true,
      handler() {
        if (!this.isLoading) {
          this.customTenantChaned = true;
        }
      },
    },
  },
  computed: {
    combinedData() {
      const combined = [];
      for (const tenant of this.tenant_row) {
        combined.push({ ...tenant, isClient: false });
        if (tenant.clients && tenant.clients.length > 0) {
          combined.push(
            ...tenant.clients.map((client) => ({
              ...client,
              isClient: true,
              showClients: tenant.selected,
              tenant_id: tenant.id,
            }))
          );
        }
      }
      return combined;
    },
    filteredData() {
      return this.combinedData.filter((rowData) => {
        if (!rowData.isClient) {
          return true;
        } else {
          return rowData.showClients;
        }
      });
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.setSourceDetailsData();
    await this.setTenantDetails();
    await this.setClientsDetails();
    this.isLoading = false;
  },
  methods: {
    isNumber,
    reArrange(data) {
      if (!data?.isClient) {
        this.tenant_row.forEach((item) => {
          if (data.id == item.id) {
            item.selected = data.selected;
          }
          if (!data.selected) {
            item.clients.forEach((clntData) => {
              clntData.selected = data.selected;
            });
          }
        });
        this.combinedData.forEach((clientData) => {
          if (clientData.isClient && clientData.tenant_id == data.id) {
            clientData.showClients = data.selected;
          }
        });
      } else if (data?.isClient) {
        this.tenant_row.forEach((tntData) => {
          if (tntData.id == data.tenant_id) {
            tntData?.clients?.forEach((clntData) => {
              if (clntData.id == data.id) {
                clntData.selected = data.selected;
              }
            });
          }
        });
      }
    },
    updateValues(sourceData, key, userType) {
      if (userType == "tenant") {
        this.customTenantChaned = true;
        this.tenant_row = this.tenant_row.map((rowData) => {
          if (sourceData?.id == rowData.id) {
            rowData[key] = sourceData[key];
          }
          return rowData;
        });
      } else if (userType == "client") {
        this.customClientChaned = true;
        this.client_row = this.client_row.map((rowData) => {
          if (sourceData?.client_id == rowData.client_id) {
            rowData[key] = sourceData[key];
          }
          return rowData;
        });
      }
    },
    checkColumn(key) {
      let colName = this.tenant_cols.filter((item) => item.label == key);
      if (colName && colName.length) return true;
      else return false;
    },
    async selectedTab(tab) {
      if (this.currentTab === tab.nav) return;
      this.currentTab = tab.nav;
      this.setBredCrumbs(this.currentTab);
      this.$router.push({
        name: this.currentTab,
      });
    },
    async setSourceDetailsData() {
      this.params.id = this.$route.params.id;
    //   let qParams = new URLSearchParams(this.params);
      const { data } = await getSourceList(this.params.id);
      console.log(data)
      console.log("drtf")
      this.sourceDetails = data?.data;
      if(data?.data?.custom_tenants?.length > 0){
        this.sourceDetails.credit_per_hit = this.sourceDetails.custom_tenants[0].credit_per_hit
      }
      this.credit_per_hit = this.sourceDetails.credit_per_hit;
      this.service_charge_per_hit = this.sourceDetails.service_charge_per_hit;
      this.customBreadCrumbs = [
        { name: this.sourceDetails.credit_source_name },
      ];
    },
    handleSearch(searchedText, userType) {
      if (userType == "tenant") {
        let value = searchedText.toLowerCase();
        let table = document.getElementById("tenantDataTable");
        let rows = table.getElementsByClassName("tenantTableRows");
        for (let i = 0; i < rows.length; i++) {
          let tenant_row = rows[i];
          let cells = tenant_row.getElementsByTagName("td");
          let found = false;
          for (let i = 0; i < cells.length; i++) {
            let cell = cells[i];
            if (cell.innerHTML.toLowerCase().indexOf(value) > -1) {
              found = true;
              break;
            }
          }
          if (found) {
            tenant_row.style.display = "";
          } else {
            tenant_row.style.display = "none";
          }
        }
      } else if (userType == "client") {
        let value = searchedText.toLowerCase();
        let table = document.getElementById("clientDataTable");
        let rows = table.getElementsByClassName("clientTableRows");
        for (let i = 0; i < rows.length; i++) {
          let client_row = rows[i];
          let cells = client_row.getElementsByTagName("td");
          let found = false;
          for (let i = 0; i < cells.length; i++) {
            let cell = cells[i];
            if (cell.innerHTML.toLowerCase().indexOf(value) > -1) {
              found = true;
              break;
            }
          }
          if (found) {
            client_row.style.display = "";
          } else {
            client_row.style.display = "none";
          }
        }
      }
    },
    handleSearchTenant(searchedTenantValue) {
      let value = searchedTenantValue.toLowerCase();
      let table = document.getElementById("clientDataTable");
      let rows = table.getElementsByClassName("clientTableRows");
      for (let i = 0; i < rows.length; i++) {
        let client_row = rows[i];
        let cells = client_row.getElementsByClassName("clientTenantName");
        let found = false;
        for (let i = 0; i < cells.length; i++) {
          let cell = cells[i];
          if (cell.innerHTML.toLowerCase().indexOf(value) > -1) {
            found = true;
            break;
          }
        }
        if (found) {
          client_row.style.display = "";
        } else {
          client_row.style.display = "none";
        }
      }
    },
    handleValues(event, key) {
      this.changeMade = true;
      this.sourceDetails[key] = event;
    },
    async setSourceData() {
      let payload = {
        id: this.$route.params.id,
        credit_per_hit: parseInt(this.credit_per_hit),
        service_charge_per_hit: 0,
      };
      if (this.credit_per_hit != "") {
        await updateSourceDetails(payload);
        this.$toast.success("Source Update Successfully");
        this.selectedTab(this.navigations[1]);
        this.isLoading = true;
        await this.setTenantDetails();
        this.isLoading = false;
        this.changeMade = false;
      } else {
        this.$toast.error("Please fill all required fields111");
      }
    },
    async setTenantDetails() {
      // if (
      //   this.sourceDetails.credit_source_name.toLowerCase() == "bvd" ||
      //   this.sourceDetails.credit_source_name.toLowerCase() ==
      //     "opencorporates" ||
      //   this.sourceDetails.credit_source_name.toLowerCase() ==
      //     "comply_advantage"
      // ) {
      //   this.tenant_cols.push({
      //     name: "Username/Key",
      //     label: "api_client_id",
      //     type: "custom",
      //   });
      // }
      // if (
      //   this.sourceDetails.credit_source_name.toLowerCase() == "dow_jones" ||
      //   this.sourceDetails.credit_source_name.toLowerCase() ==
      //     "company_watch" ||
      //   this.sourceDetails.credit_source_name.toLowerCase() == "world_check" ||
      //   this.sourceDetails.credit_source_name.toLowerCase() == "clarifiedby"
      // ) {
      //   this.tenant_cols.push(
      //     {
      //       name: "Username/Key",
      //       label: "api_client_id",
      //       type: "custom",
      //     },
      //     {
      //       name: "Password",
      //       label: "api_client_secret",
      //       type: "custom",
      //     }
      //   );
      // }
      // if (this.sourceDetails.credit_source_name.toLowerCase() == "factiva") {
      //   this.tenant_cols.push(
      //     {
      //       name: "Username/Key",
      //       label: "api_client_id",
      //       type: "custom",
      //     },
      //     {
      //       name: "Password",
      //       label: "api_client_secret",
      //       type: "custom",
      //     },
      //     {
      //       name: "Name Space",
      //       label: "namespace",
      //       type: "custom",
      //     }
      //   );
      // }
      const tenantsData = await getTenantDetails();
      let mappedData = tenantsData?.data?.tenants?.map((tntRes) => {
        const tenantData = this.sourceDetails?.custom_tenants?.find(
          (res) => res.tenant_id == tntRes?.tenant_id
        );
        return {
          title: tntRes?.tenant_name,
          id: tntRes?.tenant_id,
          credit_per_hit: tenantData?.credit_per_hit
            ? tenantData.credit_per_hit
            : this.credit_per_hit,
          service_charge_per_hit: 0,
          api_client_id: tenantData?.api_client_id
            ? tenantData.api_client_id
            : "",
          api_client_secret: tenantData?.api_client_secret
            ? tenantData.api_client_secret
            : "",
          nampespace: tenantData?.nampespace ? tenantData.nampespace : "",
          selected: tenantData ? true : false,
          default: tenantData ? tenantData.default : true,
        };
      });
      this.tenant_row = mappedData;
      await this.setClientsDetails();
    },
    async setClientsDetails() {
      const tenantsData = await getTenantDetails();
      const client = await creditAndSourceList();
      console.log(client.data.data)
      let allClients = client.data.data.reduce((prev, next) => prev.concat(next.custom_clients), []);
      let finalClientList = [];
      tenantsData?.data?.tenants?.map((tntRes) => {
        tntRes?.clients?.map((clntRes) => {
          const clientData = allClients?.find(
            (res) => res.client_id == clntRes?.client_id
          );
          finalClientList?.push({
            tenant_title: tntRes?.tenant_name,
            client_title: clntRes?.client_name,
            id: tntRes?.tenant_id,
            client_id: clntRes?.client_id,
            credit_per_hit: clientData?.credit_per_hit
              ? clientData.credit_per_hit
              : this.credit_per_hit,
            service_charge_per_hit: clientData?.service_charge_per_hit
              ? clientData.service_charge_per_hit
              : this.service_charge_per_hit,
            api_client_id: clientData?.api_client_id
              ? clientData.api_client_id
              : "",
            api_client_secret: clientData?.api_client_secret
              ? clientData.api_client_secret
              : "",
            nampespace: clientData?.nampespace ? clientData.nampespace : "",
            selected: clientData ? true : false,
            default: clientData ? clientData.default : true,
          });
        });
      });
      console.log(finalClientList)
      this.client_row = finalClientList;
    },
    searchTenant() {
      this.filterTenant = !this.filterTenant;
    },
    defaultSource(source, userType) {
      if (userType == "tenant") {
        this.tenant_row.forEach((res) => {
          if (res.id == source.id) {
            if (source.default) {
              res.default = false;
            } else {
              res.credit_per_hit = this.credit_per_hit;
              res.service_charge_per_hit = this.service_charge_per_hit;
              res.default = true;
            }
          }
        });
      } else if (userType == "client") {
        this.client_row.forEach((res) => {
          if (res?.client_id == source.client_id) {
            if (source.default) {
              res.default = false;
            } else {
              res.credit_per_hit = this.sourceDetails.credit_per_hit;
              res.service_charge_per_hit =
                this.sourceDetails.service_charge_per_hit;
              res.default = true;
            }
          }
        });
      }
    },
    async assignTenants() {
      try {
        this.savingAssignPacks = true;
        const selectedTenants = this.tenant_row.filter(
          (res) => res.default == false
        );
        let payload = {
          credit_source_id: this.$route.params.id,
          tenant_details: [],
        };
        selectedTenants.forEach((res) => {
          payload.tenant_details.push({
            tenant_id: res.id,
            credit_per_hit: res.credit_per_hit,
            service_charge_per_hit: res.service_charge_per_hit,
            default: res.default,
            api_client_id: res.client_id ? res.client_id : "",
            api_client_secret: res.api_client_secret
              ? res.api_client_secret
              : "",
            nampespace: res.nampespace ? res.nampespace : "",
          });
        });
        payload.tenant_details.clients = [];
        await assignTenant(payload);
        this.savingAssignPacks = false;
        this.$toast.success("Custom tenants updated.");
        this.customTenantChaned = false;
      } catch (err) {
        this.$toast.error("Something went wrong.");
        this.savingAssignPacks = false;
      }
    },
    async assignClients() {
      try {
        this.savingAssignPacks = true;
        const selectedClients = this.client_row.filter(
          (res) => res.default == false
        );
        let payload = {
          credit_source_id: this.$route.params.id,
          tenant_details: [],
        };
        let clients = [];
        selectedClients?.forEach((res) => {
          clients = [];
          clients.push({
            client_id: res.client_id,
            credit_per_hit: res.credit_per_hit,
            service_charge_per_hit: 0,
            default: res.default,
            api_client_id: "",
            api_client_secret: res.api_client_secret
              ? res.api_client_secret
              : "",
            nampespace: res.nampespace ? res.nampespace : "",
          });
          payload.tenant_details.push({
            tenant_id: res.id,
            credit_per_hit: res.credit_per_hit,
            service_charge_per_hit: 0,
            default: res.default,
            api_client_id: "",
            api_client_secret: res.api_client_secret
              ? res.api_client_secret
              : "",
            nampespace: res.nampespace ? res.nampespace : "",
            clients: clients,
          });
        });

        await assignTenant(payload);
        this.savingAssignPacks = false;
        this.$toast.success("Custom clients updated.");
        this.changeMadeAssign = false;
      } catch (err) {
        this.$toast.error("Something went wrong.");
        this.savingAssignPacks = false;
      }
    },
    handleSelect(selectedAll) {
      if (selectedAll) {
        this.tenant_row.forEach((res) => {
          res.selected = true;
        });
      } else {
        this.tenant_row.forEach((res) => {
          res.selected = false;
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.formulate-input[data-classification="text"] input {
  border-radius: 0.5rem;
}
.clientRow {
  padding-left: 45px;
}
::v-deep {
  .disableInput {
    input {
      background: #f9fafb !important;
      cursor: not-allowed;
    }
    label {
      font-weight: 800;
    }
  }
  .allInputsLables {
    label {
      font-weight: 800;
    }
  }
}
</style>
